import { lazy } from 'react';

const routes = [
  { path: '/', Element: lazy(() => import('./views/pages/Dashboard')), roles: [] },
  { path: '/accounting', Element: lazy(() => import('./views/pages/Accounting/Dashboard')), roles: ['admin-accounting'] },
  { path: '/management', Element: lazy(() => import('./views/pages/Accounting/Management')), roles: ['admin-accounting'] },
  { path: '/setting', Element: lazy(() => import('./views/settings/Index')), roles: [] },
  { path: '/chats', Element: lazy(() => import('./views/pages/ChatLists')), roles: [] },
  { path: '/chats/:token', Element: lazy(() => import('./views/pages/Chats')), roles: [] },
  { path: '/referral-status', Element: lazy(() => import('./views/pages/ReferralStatus')), roles: ['admin-hospital'] },
  { path: '/referral/create', Element: lazy(() => import('./components/Referral/Create')), roles: ['admin-hospital'] },
  { path: '/referral/:refCode', Element: lazy(() => import('./components/Referral/Edit')), roles: ['admin-hospital'] },
  { path: '/profile', Element: lazy(() => import('./views/auth/Profile')), roles: [] },
  { path: '/closeAccount', Element: lazy(() => import('./views/auth/CloseAccount')), roles: [] },
  { path: '/consult/:refCode', Element: lazy(() => import('./components/ConsultCard/Detail')), roles: [] },
  { path: '/company-manage', Element: lazy(() => import('./views/pages/Companies')), roles: [] },
  { path: '/company-manage/create', Element: lazy(() => import('./views/pages/Companies/Create')), roles: [] },
  { path: '/company-manage/:code', Element: lazy(() => import('./views/pages/Companies/Edit')), roles: [] },
  { path: '/clinic', Element: lazy(() => import('./views/pages/Clinic')), roles: [] },
  { path: '/clinic/add', Element: lazy(() => import('./views/pages/Clinic/AddNewCase')), roles: [] },
  { path: '/clinic/add/:patCode', Element: lazy(() => import('./views/pages/Clinic/Create')), roles: [] },
  { path: '/clinic/edit/:refCode', Element: lazy(() => import('./views/pages/Clinic/Create')), roles: [] },
  { path: '/patient/create', Element: lazy(() => import('./components/Patient/Create')), roles: ['admin-hospital', 'systemadmin'] },
  { path: '/patient/health', Element: lazy(() => import('./views/pages/Patients/Health')), roles: ['admin-hospital', 'systemadmin'] },
  { path: '/patient/edit/:patCode', Element: lazy(() => import('./components/Patient/Edit')), roles: ['admin-hospital', 'systemadmin'] },
  { path: '/patients', Element: lazy(() => import('./views/pages/Patients')), roles: ['admin-hospital'] },
  { path: '/patients/:patCode', Element: lazy(() => import('./views/pages/Patients/Detail')), roles: ['admin-hospital'] },
  { path: '/doctor-profile', Element: lazy(() => import('./views/pages/Doctors')), roles: ['admin-hospital'] },
  { path: '/doctor-profile/:docCode', Element: lazy(() => import('./views/pages/Doctors/Detail')), roles: ['admin-hospital'] },
  { path: '/doctor/create', Element: lazy(() => import('./components/Doctor/Create')), roles: ['admin-hospital', 'systemadmin'] },
  { path: '/doctor/edit/:docCode', Element: lazy(() => import('./components/Doctor/Edit')), roles: ['admin-hospital', 'systemadmin'] },
  { path: '/report', Element: lazy(() => import('./views/pages/Report')), roles: ['admin-hospital', 'admin-accounting'] },
  { path: '/export', Element: lazy(() => import('./views/pages/Report/export')), roles: ['systemadmin'] },
  { path: '/master-data', Element: lazy(() => import('./views/pages/Master')), roles: ['systemadmin'] },
  { path: '/master-data/apps', Element: lazy(() => import('./views/pages/Master/Apps')), roles: ['systemadmin'] },
  { path: '/master-data/campaigns', Element: lazy(() => import('./views/pages/Master/Campaigns')), roles: ['systemadmin'] },
  { path: '/master-data/cancelReason', Element: lazy(() => import('./views/pages/Master/CancelReason')), roles: ['systemadmin'] },
  { path: '/master-data/coupons', Element: lazy(() => import('./views/pages/Master/Coupons')), roles: ['systemadmin'] },
  { path: '/master-data/deactivationReason', Element: lazy(() => import('./views/pages/Master/DeactivationReason')), roles: ['systemadmin'] },
  { path: '/master-data/doctorAcademy', Element: lazy(() => import('./views/pages/Master/DoctorAcademy')), roles: ['systemadmin'] },
  { path: '/master-data/doctorDocumentType', Element: lazy(() => import('./views/pages/Master/DoctorDocumentType')), roles: ['systemadmin'] },
  { path: '/master-data/doctorFee', Element: lazy(() => import('./views/pages/Master/DoctorFee')), roles: ['systemadmin'] },
  { path: '/master-data/doctorPrefix', Element: lazy(() => import('./views/pages/Master/DoctorPrefix')), roles: ['systemadmin'] },
  { path: '/master-data/emergency', Element: lazy(() => import('./views/pages/Master/Emergency')), roles: ['systemadmin'] },
  { path: '/master-data/faqs', Element: lazy(() => import('./views/pages/Master/Faqs')), roles: ['systemadmin'] },
  { path: '/master-data/groupSpecialtys', Element: lazy(() => import('./views/pages/Master/GroupSpecialtys')), roles: ['systemadmin'] },
  { path: '/master-data/hospitalGroups', Element: lazy(() => import('./views/pages/Master/HospitalGroups')), roles: ['systemadmin'] },
  { path: '/master-data/hospitalTypes', Element: lazy(() => import('./views/pages/Master/HospitalTypes')), roles: ['systemadmin'] },
  { path: '/master-data/lang', Element: lazy(() => import('./views/pages/Master/Lang')), roles: ['systemadmin'] },
  { path: '/master-data/langConsult', Element: lazy(() => import('./views/pages/Master/LangConsult')), roles: ['systemadmin'] },
  { path: '/master-data/lineOwnerCode', Element: lazy(() => import('./views/pages/Master/LineOwnerCode')), roles: ['systemadmin'] },
  { path: '/master-data/manuals', Element: lazy(() => import('./views/pages/Master/Manuals')), roles: ['systemadmin'] },
  { path: '/master-data/organ', Element: lazy(() => import('./views/pages/Master/Organ')), roles: ['systemadmin'] },
  { path: '/master-data/prefix', Element: lazy(() => import('./views/pages/Master/Prefix')), roles: ['systemadmin'] },
  { path: '/master-data/specialties', Element: lazy(() => import('./views/pages/Master/Specialties')), roles: ['systemadmin'] },
  { path: '/master-data/symptom', Element: lazy(() => import('./views/pages/Master/Symptom')), roles: ['systemadmin'] },
  { path: '/master-data/tagsAndEvents', Element: lazy(() => import('./views/pages/Master/TagsAndEvents')), roles: ['systemadmin'] },
  { path: '/master-data/whitelabelAdmin', Element: lazy(() => import('./views/pages/Master/WhitelabelAdmin')), roles: ['systemadmin'] },
  { path: '/master-data/whitelabelApi', Element: lazy(() => import('./views/pages/Master/WhitelabelApi')), roles: ['systemadmin'] },
  { path: '/master-data/whitelabelDoctor', Element: lazy(() => import('./views/pages/Master/WhitelabelDoctor')), roles: ['systemadmin'] },
  { path: '/master-data/whitelabelEasycall', Element: lazy(() => import('./views/pages/Master/WhitelabelEasycall')), roles: ['systemadmin'] },
  { path: '/master-data/whitelabelPatient', Element: lazy(() => import('./views/pages/Master/WhitelabelPatient')), roles: ['systemadmin'] },
  { path: '/account-admins', Element: lazy(() => import('./views/pages/Account/AdminAccount')), roles: ['systemadmin'] },
  { path: '/account-admins/:code', Element: lazy(() => import('./views/pages/Account/AdminAccountDetail')), roles: ['systemadmin'] },
  { path: '/account-admins/create', Element: lazy(() => import('./views/pages/Account/Admin/Create')), roles: ['systemadmin'] },
  { path: '/account-admins/edit/:code', Element: lazy(() => import('./views/pages/Account/Admin/Edit')), roles: ['systemadmin'] },
  { path: '/account-doctors', Element: lazy(() => import('./views/pages/Account/DoctorAccount')), roles: ['systemadmin'] },
  { path: '/account-doctors/:code', Element: lazy(() => import('./views/pages/Account/DoctorAccountDetail')), roles: ['systemadmin'] },
  { path: '/account-patients', Element: lazy(() => import('./views/pages/Account/PatientAccount')), roles: ['systemadmin'] },
  { path: '/account-patients/:code', Element: lazy(() => import('./views/pages/Account/PatientAccountDetail')), roles: ['systemadmin'] },
  { path: '/payment-manage', Element: lazy(() => import('./views/pages/Payment')), roles: ['systemadmin'] },
  { path: '/payment-manage/add', Element: lazy(() => import('./views/pages/Payment/Add')), roles: ['systemadmin'] },
  { path: '/payment-manage/:code', Element: lazy(() => import('./views/pages/Payment/Detail')), roles: ['systemadmin'] },
  { path: '/coupon-manage', Element: lazy(() => import('./views/pages/Coupon')), roles: ['systemadmin'] },
  { path: '/coupon-manage/add', Element: lazy(() => import('./views/pages/Coupon/Add')), roles: ['systemadmin'] },
  { path: '/coupon-manage/:code', Element: lazy(() => import('./views/pages/Coupon/Detail')), roles: ['systemadmin'] },
  { path: '/insurance', Element: lazy(() => import('./views/pages/Insurance')), roles: ['systemadmin'] },
  { path: '/insurance/add', Element: lazy(() => import('./views/pages/Insurance/Add')), roles: ['systemadmin'] },
  { path: '/insurance/detail/:code', Element: lazy(() => import('./views/pages/Insurance/Detail')), roles: ['systemadmin'] },
  { path: '/insurance/edit/:code', Element: lazy(() => import('./views/pages/Insurance/Edit')), roles: ['systemadmin'] },
  { path: '/token-manage', Element: lazy(() => import('./views/pages/Token')), roles: ['systemadmin'] },
  { path: '/token-manage/add', Element: lazy(() => import('./views/pages/Token/Add')), roles: ['systemadmin'] },
  { path: '/token-manage/detail/:code', Element: lazy(() => import('./views/pages/Token/Detail')), roles: ['systemadmin'] },
  { path: '/token-manage/edit/:code', Element: lazy(() => import('./views/pages/Token/Edit')), roles: ['systemadmin'] },
  { path: '/patient/import', Element: lazy(() => import('./components/Patient/Import')), roles: [] },
  { path: '/tools', Element: lazy(() => import('./views/tools')), roles: ['systemadmin'] },
  { path: '/order-medicine', Element: lazy(() => import('./views/pages/OrderMedicine/Index')), roles: ['admin-hospital'] },
  { path: '/order-medicine/:refCode', Element: lazy(() => import('./views/pages/OrderMedicine/Detail')), roles: ['admin-hospital'] },
  { path: '/hospital', Element: lazy(() => import('./views/pages/Hospital')), roles: ['systemadmin'] },
  { path: '/hospital/:code', Element: lazy(() => import('./views/pages/Hospital/Detail')), roles: ['systemadmin'] },
  { path: '/hospital/create', Element: lazy(() => import('./components/Hospital/Create')), roles: ['systemadmin'] },
  { path: '/hospital/edit/:code', Element: lazy(() => import('./components/Hospital/Edit')), roles: ['systemadmin'] },
  { path: '/dashboard', Element: lazy(() => import('./views/pages/Dashboard/Dashboard')), roles: ['admin-hospital'] },
  { path: '/referral-visit', Element: lazy(() => import('./views/pages/ReferralVisit')), roles: ['admin-hospital'] },
  { path: '/article', Element: lazy(() => import('./views/pages/Article')), roles: ['systemadmin'] },
  { path: '/article/:code', Element: lazy(() => import('./views/pages/Article/Detail')), roles: ['systemadmin'] },
  { path: '/article/create', Element: lazy(() => import('./components/Article/Create')), roles: ['systemadmin'] },
  { path: '/article/edit/:code', Element: lazy(() => import('./components/Article/Edit')), roles: ['systemadmin'] },
  { path: '/doctoratoz', Element: lazy(() => import('./views/pages/Doctoratoz')), roles: ['admin-hospital'] },
  { path: '/doctoratoz/create', Element: lazy(() => import('./components/Doctoratoz/Create')), roles: ['admin-hospital'] },
  { path: '/doctoratoz/:refCode', Element: lazy(() => import('./components/Doctoratoz/Edit')), roles: ['admin-hospital'] },
  { path: '/iomt', Element: lazy(() => import('./views/pages/Iomt')), roles: ['systemadmin'] },
  { path: '/iomt/:patCode', Element: lazy(() => import('./views/pages/IomtHealthData')), roles: ['systemadmin'] }
];

export default routes;
