import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IOMT_CLEAR,
  IOMT_DATA,
  IS_LOADING
} from './types';

export const getIomt = (params = [], limit = 100, nextKey = '') => async dispatch => {
  let isAddMore = false;
  let url = 'patientHealthRecord';
  const newParams = [
    `limit=${limit}`,
    ...params
  ];
  url += `?${newParams.join('&')}`;
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: IOMT_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { IOMT_DATA: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || response.data || [];
    dispatch({
      type: IOMT_DATA,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { IOMT_DATA: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IOMT_DATA, playload: { status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { IOMT_DATA: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};
