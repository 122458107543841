import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';

import {
  IS_LOADING,
  AUTH_SIGN_IN,
  AUTH_SIGN_OUT,
  AUTH_REGISTER,
  AUTH_CHANGE_PASSWORD,
  AUTH_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_VERIFY_OTP,
  AUTH_FORGOT_PASSWORD_CHANGE,
  AUTH_ADD_CONTACT,
  AUTH_ADD_CONTACT_VERIFY,
  AUTH_ADD_CONTACT_UPDATE,
  AUTH_CHANGE_CONTACT_DEFAULT,
  AUTH_CLOST_ACCOUNT,
  AUTH_CLEAR,
  AUTH_GENERATE_LINK,
  AUTH_CREATE_ACCOUNT
} from './types';
import {
  LINE_CLIENT_ID,
  LINE_CLIENT_SECRET,
  LINE_REDIRECT_URI,
  ROLE_ACCEPT,
  SMS_PROVIDER_NAME
} from '../configs';

export const authSignIn = params => async dispatch => {
  const url = '/accounts/adminlogin';
  dispatch({ type: AUTH_CLEAR });
  dispatch({
    type: IS_LOADING,
    status: true,
    names: { AUTH_SIGN_IN: true }
  });
  const companyCode = localStorage.getItem('company-code');
  await axios.post(url, params).then(response => {
    let loggedIn = false;
    let accessToken = null;
    let profile = {};
    let roles = [];
    let message = '';
    let isHasRoleAccess = false;
    let social = null;
    let firstLogin = false;
    if (response?.data?.group) {
      roles = response.data.group;
    } else {
      roles = (response?.data?.user && response?.data?.user['cognito:groups'] && Object.values(response?.data?.user['cognito:groups'])) || [];
    }
    if (response?.data?.profiles?.length > 0) {
      social = response?.data?.userRef !== 'null' ? 'social' : null;
      loggedIn = _.has(response.data, 'accessToken');
      accessToken = response?.data?.accessToken || null;
      const data = response.data.profiles.find(item => item.group === params.role);
      if (data && data.profile) {
        profile = data.profile;
        isHasRoleAccess = profile.ekyc || false;
        firstLogin = profile?.firstLogin === true;
        Bugsnag.setUser(profile.adminCode, 'developer@doctoratoz.co', 'Bugsnag Web Admin');
      } else {
        profile = response.data.profiles[0].profile;
      }
      let refCompanyCode = null;
      if (profile?.refCompanyCode && profile?.refCompanyCode !== 'null') {
        refCompanyCode = profile?.refCompanyCode;
      } else if (typeof profile?.refsCompanyCode === 'object' && profile?.refsCompanyCode?.length > 0) {
        refCompanyCode = profile?.refsCompanyCode[0];
      }
      const checkAccessCompany = !companyCode || (companyCode && refCompanyCode === companyCode);
      if ((!roles.includes('systemadmin') && (profile?.restrictHospitals?.length === 0 || profile?.refsCompanyCode?.length === 0)) || !roles?.length || (roles?.length > 0 && roles.filter(r => ROLE_ACCEPT.includes(r)).length === 0) || !checkAccessCompany) {
        loggedIn = false;
        accessToken = null;
        profile = {};
        isHasRoleAccess = false;
        firstLogin = false;
        message = 'This account is not available. Please contact support';
      }
    } else if (!_.has(response.data, 'profiles') || response.data.profiles.length === 0) {
      message = (response?.data?.message !== 'Success' && response?.data?.message) || 'This account is not available. Please contact support';
    } else if (response?.data?.message) {
      message = response.data.message;
    }
    const playload = {
      loggedIn,
      accessToken,
      profile,
      social,
      isAccess: false,
      message,
      firstLogin,
      isHasRoleAccess,
      roles
    };
    dispatch({ type: AUTH_SIGN_IN, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_SIGN_IN: false } });
  }).catch(err => {
    let error = err;
    const message = 'This account is not available. Please contact support';
    const playload = {
      message
    };
    dispatch({ type: AUTH_SIGN_IN, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_SIGN_IN: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const authSignOut = () => dispatch => {
  localStorage.removeItem('authId');
  dispatch({ type: AUTH_SIGN_OUT });
};

export const authClear = () => dispatch => {
  dispatch({ type: AUTH_CLEAR });
};

export const authValidateToken = async token => {
  const url = `/accounts/checkadmin/${token}`;
  return axios.get(url).then(response => {
    if (_.has(response, 'data.profiles') && _.isArray(response.data.profiles) && response.data.profiles.length > 0) {
      const user = response.data.profiles.filter(o => o.group === 'admin');
      if (user.length > 0 && _.has(user[0], 'profile.adminId') && _.has(user[0], 'profile.adminCode')) {
        const currentDomain = window.location.hostname;
        const params = {
          userId: user[0].profile.adminId,
          userCode: user[0].profile.adminCode,
          userRole: 'admin',
          system_channel: currentDomain,
          domain: currentDomain
        };
        const urlLogging = 'logging/lastLogin';
        axios.post(urlLogging, params);
      }
      return response.data;
    }
    return { error: 'no profile' };
  }).catch(err => {
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
    return { error };
  });
};

export const authAccessToken = (data, token) => async dispatch => {
  let profile = {};
  let roles = [];
  let isHasRoleAccess = false;
  let social = null;
  let message = null;
  let firstLogin = false;
  if (_.has(data, 'profiles') && data.profiles.length > 0) {
    social = data?.social;
    const profiles = data.profiles.find(item => item.group === data.role);
    if (profiles && profiles.profile) {
      message = 'Successful';
      profile = profiles.profile;
      isHasRoleAccess = profile.ekyc || false;
      firstLogin = profile?.firstLogin === true;
      Bugsnag.setUser(profile.adminCode, 'developer@doctoratoz.co', 'Bugsnag Web Admin');
    } else {
      profile = data.profiles[0].profile;
    }
  }
  if (_.has(data, 'group')) {
    roles = data.group;
  }
  const playload = {
    loggedIn: true,
    accessToken: token,
    profile,
    social,
    message,
    isAccess: true,
    firstLogin,
    isHasRoleAccess,
    roles
  };
  dispatch({ type: AUTH_SIGN_IN, playload });
};

export const authRegister = params => async dispatch => {
  const url = '/accounts/register';

  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_REGISTER: true } });
  await axios.post(url, params).then(response => {
    const playload = {};
    if (_.has(response.data, 'profiles') && response.data.profiles.length > 0) {
      const data = response.data.profiles.filter(item => item.group === params.role);
      if (data.length > 0) {
        playload.isRegisterSuccess = true;
        playload.profile = data[0].profile;
      }
    } else {
      playload.isRegisterSuccess = false;
      if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
        playload.message = response.data.errors[0].msg;
      } else {
        playload.message = response.data.message;
      }
    }
    playload.status = 'success';
    dispatch({ type: AUTH_REGISTER, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_REGISTER: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_REGISTER: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const authChangePassword = (token, oldPassword, newPassword) => async dispatch => {
  const url = '/accounts/changePassword';
  const params = {
    accessToken: token,
    oldPassword,
    newPassword
  };

  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_CHANGE_PASSWORD: true } });
  await axios.put(url, params).then(response => {
    const playload = {};
    if (response?.data?.message === 'Success') {
      playload.isChangePasswordSuccess = true;
    } else {
      playload.isChangePasswordSuccess = false;
      if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
        playload.message = response.data.errors[0].msg;
      } else {
        playload.message = response.data.message;
      }
    }
    playload.status = 'success';
    dispatch({ type: AUTH_CHANGE_PASSWORD, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_CHANGE_PASSWORD: false } });
  }).catch(err => {
    let error = err;
    dispatch({
      type: AUTH_CHANGE_PASSWORD,
      playload: {
        isChangePasswordSuccess: false,
        message: error.response.data.message
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_CHANGE_PASSWORD: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const authForgotPassword = username => async dispatch => {
  const url = '/accounts/forgotPassword';
  const params = {
    username
  };

  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_FORGOT_PASSWORD: true } });
  await axios.put(url, params).then(response => {
    let { message } = response.data;
    if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
      message = response.data.errors[0].msg;
    }
    dispatch({ type: AUTH_FORGOT_PASSWORD, playload: { ref: _.has(response.data, 'data.ref') ? response.data.data.ref : null, msg: message, status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_FORGOT_PASSWORD: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_FORGOT_PASSWORD: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const authForgotPasswordVerifyOTP = (ref, params) => async dispatch => {
  const url = `/accounts/forgotPassword/otp/verify/${ref}`;

  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_FORGOT_PASSWORD_VERIFY_OTP: true } });
  await axios.put(url, params).then(response => {
    let verify = true;
    let { message } = response.data;
    if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
      verify = false;
      message = response.data.errors[0].msg;
    } else if (_.has(response.data, 'error')) {
      verify = false;
    }
    dispatch({
      type: AUTH_FORGOT_PASSWORD_VERIFY_OTP,
      playload: {
        ref,
        verify,
        msg: message,
        status: 'success'
      }
    });
    dispatch({
      type: IS_LOADING,
      status: false,
      names: {
        AUTH_FORGOT_PASSWORD_VERIFY_OTP: false
      }
    });
  }).catch(err => {
    let error = err;
    dispatch({
      type: IS_LOADING,
      status: false,
      names: {
        AUTH_FORGOT_PASSWORD_VERIFY_OTP: false
      }
    });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const authForgotPasswordChange = params => async dispatch => {
  const url = '/accounts/forgotPasswordChange';

  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_FORGOT_PASSWORD_CHANGE: true } });
  await axios.put(url, params).then(response => {
    const playload = {};
    if (_.has(response.data, 'user') && response.data.message === 'Success') {
      playload.isChangePasswordSuccess = true;
      playload.status = 'success';
    } else {
      playload.isChangePasswordSuccess = false;
      playload.status = 'error';
      if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
        playload.message = response.data.errors[0].msg;
      } else {
        playload.message = response.data.message;
      }
    }
    dispatch({ type: AUTH_FORGOT_PASSWORD_CHANGE, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_FORGOT_PASSWORD_CHANGE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_FORGOT_PASSWORD_CHANGE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const authSignInWithLINE = async code => {
  const url = 'https://api.line.me/oauth2/v2.1/token';
  const params = new URLSearchParams({
    grant_type: 'authorization_code',
    code,
    client_id: LINE_CLIENT_ID,
    client_secret: LINE_CLIENT_SECRET,
    redirect_uri: LINE_REDIRECT_URI
  }).toString();
  return axios.post(url, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }).then(response => response.data);
};

export const updateSignal = async (code, signalId) => {
  const url = `/doctors/${code}`;
  return new Promise(reslove => {
    axios.put(url, {
      signalId
    }).then(response => {
      reslove(response.data);
    }).catch(() => {
      reslove({});
    });
  });
};

export const getPatientSignal = async code => {
  const codes = code.split('-');
  let url = `/patients?codes[0]=${code}`;
  if (codes.length > 1) {
    url = `/patients?codes[0]=${codes[0]}`;
  }
  return new Promise(reslove => {
    axios.get(url).then(response => {
      if (_.has(response, 'data.data') && _.isArray(response.data.data) && response.data.data.length > 0 && _.has(response.data.data[0], 'signalId')) {
        reslove(response.data.data[0].signalId);
      } else {
        reslove('');
      }
    }).catch(() => {
      reslove('');
    });
  });
};

export const authAddContect = (code, value, type = 'mobile', roleType = 'admin') => async dispatch => {
  const url = type === 'mobile' ? '/accounts/addMobile' : '/accounts/addEmail';
  const params = {
    roleType,
    channel: SMS_PROVIDER_NAME,
    code
  };
  if (type === 'mobile') {
    params.newMobile = value;
  } else {
    params.newEmail = value;
  }

  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_ADD_CONTACT: true } });
  await axios.post(url, params).then(response => {
    const playload = {};
    if (_.has(response.data, 'data.ref')) {
      playload.isAddContact = true;
      playload.authRef = response.data.data.ref;
    } else {
      playload.isAddContact = false;
      if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
        playload.message = response.data.errors[0].msg;
      } else {
        playload.message = response.data.message;
      }
    }
    playload.status = 'success';
    dispatch({ type: AUTH_ADD_CONTACT, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT: false } });
  }).catch(err => {
    let error = err;
    const playload = {
      status: 'success',
      isAddContact: false
    };
    dispatch({ type: AUTH_ADD_CONTACT, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const authAddContactVerify = (refCode, value, OTP, type = 'mobile') => async dispatch => {
  const url = type === 'mobile' ? `/accounts/addMobile/otp/verify/${refCode}` : `/accounts/addEmail/otp/verify/${refCode}`;
  const params = {
    channel: SMS_PROVIDER_NAME,
    otpNumber: OTP
  };
  if (type === 'mobile') {
    params.phone = value;
  } else {
    params.email = value;
  }

  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_ADD_CONTACT_VERIFY: true } });
  await axios.put(url, params).then(response => {
    const playload = {
      isAddContactVerify: true,
      message: response.data.message
    };
    if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
      playload.isAddContactVerify = false;
      playload.message = response.data.errors[0].msg;
    } else if (_.has(response.data, 'error')) {
      playload.isAddContactVerify = false;
    }
    playload.status = 'success';
    dispatch({ type: AUTH_ADD_CONTACT_VERIFY, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT_VERIFY: false } });
  }).catch(err => {
    let error = err;
    const playload = {
      status: 'success',
      isAddContactVerify: false
    };
    dispatch({ type: AUTH_ADD_CONTACT_VERIFY, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT_VERIFY: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const authAddContactUpdate = (code, value, type = 'mobile', roleType = 'admin') => async dispatch => {
  const url = type === 'mobile' ? '/accounts/addMobileConfirm' : '/accounts/addEmailConfirm';
  const params = {
    roleType,
    channel: SMS_PROVIDER_NAME,
    code
  };
  if (type === 'mobile') {
    params.mobile = value;
  } else {
    params.email = value;
  }

  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_ADD_CONTACT_UPDATE: true } });
  await axios.post(url, params).then(response => {
    const playload = {
      isAddContactUpdate: true,
      message: response.data.message
    };
    if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
      playload.isAddContactUpdate = false;
      playload.message = response.data.errors[0].msg;
    } else if (_.has(response.data, 'error')) {
      playload.isAddContactUpdate = false;
    }
    playload.status = 'success';
    dispatch({ type: AUTH_ADD_CONTACT_UPDATE, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT_UPDATE: false } });
  }).catch(err => {
    let error = err;
    const playload = {
      status: 'success',
      isAddContactUpdate: false
    };
    dispatch({ type: AUTH_ADD_CONTACT_UPDATE, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const authChangeContactDefault = (code, value, currentPassword, type = 'mobile', roleType = 'admin') => async dispatch => {
  const url = type === 'mobile' ? '/accounts/changeMainMobile' : '/accounts/changeMainEmail';
  const params = {
    roleType,
    code,
    currentPassword
  };
  if (type === 'mobile') {
    params.mobile = value;
  } else {
    params.email = value;
  }

  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_CHANGE_CONTACT_DEFAULT: true } });
  await axios.post(url, params).then(response => {
    const playload = {
      isChangeContactDefault: true,
      message: response.data.message
    };
    if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
      playload.isChangeContactDefault = false;
      playload.message = response.data.errors[0].msg;
    } else if (_.has(response.data, 'error')) {
      playload.isChangeContactDefault = false;
    }
    if (playload.message === 'Incorrect username or password.') {
      playload.message = 'Incorrect password';
    }
    playload.status = 'success';
    dispatch({ type: AUTH_CHANGE_CONTACT_DEFAULT, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_CHANGE_CONTACT_DEFAULT: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_CHANGE_CONTACT_DEFAULT: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const authCloseAccount = (code, reason, currentPassword, roleType = 'admin') => async dispatch => {
  const url = '/accounts/close';
  const params = {
    roleType,
    code,
    reason,
    currentPassword
  };

  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_CLOST_ACCOUNT: true } });
  await axios.post(url, params).then(response => {
    const playload = {
      isCloseAccount: true,
      message: response.data.message
    };
    if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
      playload.isCloseAccount = false;
      playload.message = response.data.errors[0].msg;
    } else if (_.has(response.data, 'error')) {
      playload.isCloseAccount = false;
    }
    if (playload.message === 'Incorrect username or password.') {
      playload.message = 'Incorrect password';
    }
    playload.status = 'success';
    dispatch({ type: AUTH_CLOST_ACCOUNT, playload });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_CLOST_ACCOUNT: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_CLOST_ACCOUNT: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getCompanyByCode = async companyCode => {
  const url = `/companies?code=${companyCode}`;
  return new Promise(reslove => {
    axios.get(url).then(response => {
      reslove(response.data);
    }).catch(() => {
      reslove('');
    });
  });
};

export const authGenerateLink = (refCode, userCode, type = 'telemed', role = 'guest') => async dispatch => {
  const url = '/auth/token ';
  const params = {
    type,
    role,
    refCode
  };
  if (role !== 'guest') {
    params.userCode = userCode;
  } else {
    params.mobile = userCode;
  }
  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_GENERATE_LINK: true } });
  await axios.post(url, params).then(response => {
    let status = 'success';
    let message = 'Successfully updated';
    if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
      status = 'error';
      message = response?.data?.message || 'Sorry something went wrong. Please contact support';
    } else if (_.has(response.data, 'error')) {
      status = 'error';
      message = response?.data?.message || 'Sorry something went wrong. Please contact support';
    }
    dispatch({ type: AUTH_GENERATE_LINK, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_GENERATE_LINK: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: AUTH_GENERATE_LINK, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_GENERATE_LINK: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const searchPlaceInMaps = (search, key) => {
  const url = '/googlemap/search';
  return new Promise(reslove => {
    axios.post(url, { search, key }).then(response => {
      reslove(response.data);
    }).catch(() => {
      reslove({});
    });
  });
};

export const authCreateAccount = (params, roleType = 'admin') => async dispatch => {
  const url = `/auth/account/${roleType}`;
  dispatch({ type: AUTH_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { AUTH_CREATE_ACCOUNT: true } });
  await axios.post(url, params).then(response => {
    const resData = response.data;
    let { message } = resData;
    let status = 'success';
    if (resData?.error || resData?.errors) {
      status = 'error';
      if (resData?.errors?.length > 0 && resData.errors[0]?.msg) {
        message = resData.errors[0].msg;
      }
    }
    dispatch({ type: AUTH_CREATE_ACCOUNT, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_CREATE_ACCOUNT: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { AUTH_CREATE_ACCOUNT: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
    dispatch({ type: AUTH_CREATE_ACCOUNT, playload: { status: 'error', message: error } });
  });
};

export const getUsageRightsByCompanyCode = async companyCode => {
  const url = `/companies?code=${companyCode}`;
  return new Promise(reslove => {
    axios.get(url).then(response => {
      reslove(response.data);
    }).catch(() => {
      reslove('');
    });
  });
};

export const getUsedUsageRightsByCompanyCode = async companyCode => {
  const url = `/referrals/requestUsed/${companyCode}`;
  return new Promise(reslove => {
    axios.get(url).then(response => {
      reslove(response.data);
    }).catch(() => {
      reslove({});
    });
  });
};
