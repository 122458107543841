import {
  ARTICLE_DATA,
  ARTICLE_DETAIL,
  ARTICLE_UPDATE,
  ARTICLE_CLEAR
} from '../actions/types';

const initialState = {
  data: [],
  detail: {},
  nextKey: ''
};

export default function ArticleReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case ARTICLE_DATA:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore ? [...state.data, ...action.playload.data] : action?.playload.data
      };
    case ARTICLE_DETAIL:
    case ARTICLE_UPDATE:
      return { ...state, ...action?.playload };
    case ARTICLE_CLEAR:
      return {
        ...state,
        detail: {},
        status: undefined,
        message: undefined,
        isAddMore: false,
        nextKey: ''
      };
    default:
      return { ...state };
  }
}
