import {
  PATIENT_FETCH,
  PATIENT_UPDATE,
  PATIENT_GET_HEALTH_RECORD,
  PATIENT_GET_DOCUMENT,
  PATIENT_CLEAR,
  PATIENT_HEALTH_FETCH
} from '../actions/types';

const initialState = {
  data: [],
  detail: {},
  dataHealthRecords: [],
  dataDocumentFiles: [],
  isAddMore: false,
  nextKey: '',
  dataDupLists: [],
  healthData: []
};

export default function PatientReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case PATIENT_FETCH:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore ? [...state.data, ...action.playload.data] : action?.playload.data
      };
    case PATIENT_HEALTH_FETCH:
      return {
        ...state,
        ...action?.playload,
        healthData: action?.playload?.isAddMore ? [...state.healthData, ...action.playload.healthData] : action?.playload.healthData
      };
    case PATIENT_GET_HEALTH_RECORD:
      return {
        ...state,
        ...action?.playload,
        dataHealthRecords: action?.playload?.isAddMore ? [...state.dataHealthRecords, ...action.playload.dataHealthRecords] : action?.playload.dataHealthRecords
      };
    case PATIENT_UPDATE:
    case PATIENT_GET_DOCUMENT:
      return { ...state, ...action.playload };
    case PATIENT_CLEAR:
      return {
        ...state,
        detail: {},
        dataDocumentFiles: [],
        status: undefined,
        message: undefined,
        isAddMore: false,
        nextKey: '',
        dataDupLists: []
      };
    default:
      return { ...state };
  }
}
