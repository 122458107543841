import {
  React,
  lazy,
  Suspense,
  useEffect,
  useCallback,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useCookies } from 'react-cookie';

import routes from './routes';
import {
  masterData,
  hospitalLists,
  masterIcd10,
  getCompanyByCode,
  getMasterWhitelabel
} from './actions';
import Loading from './components/Loading';
import './App.scss';
import 'bootstrap';
import InitialComponent from './components/InitialComponent';
import WhiteLabelConfig from './configs/whitelabel';
import { getFileUrl } from './helpers/utilities';
import { SUB_DOMAIN } from './configs';

const SignIn = lazy(() => import('./views/pages/Signin'));
const AppLayout = lazy(() => import('./layouts/AppLayout'));
const NotFound = lazy(() => import('./views/errors/NotFound'));
const DashboardDaz = lazy(() => import('./views/pages/DashboardDaz/DashboardDaz'));
const Mini = lazy(() => import('./views/pages/Dashboard/Mini'));
const Version = lazy(() => import('./views/pages/Version'));
let toastifyPosition = 'top-right';

const App = () => {
  const [loadAuth, setLoadAuth] = useState(false);
  const [currentRole, setCurrentRole] = useState();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const loading = useSelector(state => state.loading);
  const auth = useSelector(selector => selector.auth);
  const [cookie] = useCookies();

  const isLoading = () => {
    if (loading.status) return true;
    if (_.filter(_.values(loading.names), name => name).length > 0) return true;
    return false;
  };

  const settingWhiteLabel = useCallback(dataParams => {
    async function fetchSetData(data) {
      const theme = {
        companyCode: data.companyCode,
        payments: _.has(data.theme, 'payment') && !_.isEmpty(data.theme.payment) ? data.theme.payment : {},
        mainColor: data.theme.mainColor,
        subColor: data.theme.subColor,
        tertiaryColor: _.has(data, 'theme.tertiaryColor') ? data.theme.tertiaryColor : '#C3AB77',
        title: data.name,
        logo: _.has(data, 'theme.marketplace_logo') ? data.theme.marketplace_logo : '',
        appForCompany: _.has(data, 'appForCompany') ? data.appForCompany : {},
        webApplication: _.has(data.theme, 'webApplication') ? data.theme.webApplication : {},
        contacts: {
          lineAdd: _.has(data, 'webLineAdd') && !_.isEmpty(data.webLineAdd) ? data.webLineAdd : '',
          phoneNumber: _.has(data, 'webTelephone') && !_.isEmpty(data.webTelephone) ? data.webTelephone : ''
        },
        logoBrand: _.has(data, 'imageNew') ? data.imageNew : ''
      };
      dispatch({ type: 'WHITELABEL_SETTING_THEME', playload: { data: theme } });
      if (_.has(theme, 'title') && _.has(theme, 'mainColor')) {
        if (_.has(theme, 'logo.key')) {
          const logoBrandUrl = await getFileUrl(theme.logo);
          if (document.getElementById('logo-brand')) {
            document.getElementById('logo-brand').src = logoBrandUrl;
            document.getElementById('logo-brand').alt = theme.title;
          }
          if (document.getElementById('logo-navbar')) {
            document.getElementById('logo-navbar').src = logoBrandUrl;
            document.getElementById('logo-navbar').alt = theme.title;
          }
          if (document.querySelector('link[rel~="icon"]')) {
            document.querySelector('link[rel~="icon"]').href = logoBrandUrl;
          }
          if (document.querySelector("meta[name~='description']")) {
            document.querySelector("meta[name~='description']").content = theme.title;
          }
          if (document.querySelector("meta[name~='author']")) {
            document.querySelector("meta[name~='author']").content = theme.title;
          }
          if (document.querySelector("meta[name~='keyword']")) {
            document.querySelector("meta[name~='keyword']").content = theme.title;
          }
        }
        document.title = theme.title;
        const style = document.createElement('style');
        document.head.appendChild(style);
        let styleCss = `section.footer { background-color: ${theme.mainColor}!important; }`;
        styleCss += `.block-banner { background-color: ${theme.mainColor}!important; }`;
        styleCss += `.sidebar .nav-link.active { background-color: ${theme.mainColor}!important; }`;
        styleCss += `.sidebar .nav-link:hover { background-color: ${theme.mainColor}!important; }`;
        styleCss += `.side-menu .block-profile.active { background-color: ${theme.mainColor}!important; }`;
        styleCss += `.bg-primary { background-color: ${theme.mainColor}!important; }`;
        styleCss += `.block-banner .login-footer { background-color: ${theme.subColor}!important; }`;
        styleCss += `.container.wallet .block-data > .selected .btn.selected { background-color: ${theme.mainColor}!important; }`;
        styleCss += `.container.wallet .block-data > .selected .btn.selected:hover { background-color: ${theme.mainColor}!important; }`;
        styleCss += `.container.wallet .block-data > .selected .btn-icon-column:hover { background-color: ${theme.mainColor}!important; }`;
        styleCss += `.btn-primary, .btn-primary.disabled:hover { background-color: ${theme.mainColor}!important; border-color: ${theme.mainColor}!important; }`;
        styleCss += `.btn-secondary, .btn-secondary.disabled:hover { background-color: ${theme.mainColor} !important; border-color: ${theme.mainColor}!important; }`;
        styleCss += `.btn-outline-secondary, .btn-outline-secondary.disabled:hover { color: ${theme.mainColor} !important; border-color: ${theme.mainColor}!important; }`;
        styleCss += `.btn-white:hover { background-color: ${theme.mainColor}!important; border-color: ${theme.mainColor}!important; }`;
        styleCss += '.loading { background: rgba(0, 0, 0, 0.7)!important; }';
        if (['#FDE304'].includes(theme.mainColor)) {
          styleCss += '.sidebar .nav-link.active, .btn-secondary, .btn-outline-secondary, .btn-primary, .btn-link, .footer, section.footer a { color: #000000!important; }';
        }
        style.innerHTML = styleCss;
      }
    }
    fetchSetData(dataParams);
  }, [dispatch]);

  useEffect(() => {
    if (/Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      toastifyPosition = 'top-center';
    }
  }, [navigator]);

  useEffect(() => {
    localStorage.removeItem('company-code');
    async function fetchData(callback = () => {}) {
      const whiteLabelMaster = await getMasterWhitelabel();
      if (_.has(whiteLabelMaster, 'data')) {
        WhiteLabelConfig.mapping = _.concat(WhiteLabelConfig.mapping, whiteLabelMaster.data);
        WhiteLabelConfig.mapping = _.unionBy(WhiteLabelConfig.mapping, 'companyCode');
      }
      const currentDomain = window.location.hostname;
      const company = WhiteLabelConfig.mapping.find(filter => filter.domainName === currentDomain);
      if (company?.companyCode) {
        const style = document.createElement('style');
        document.head.appendChild(style);
        const styleCss = '.loading { background: rgba(0, 0, 0, 1.0)!important; }';
        style.innerHTML = styleCss;
        const response = await getCompanyByCode(company?.companyCode);
        if (_.has(response, 'data') && _.isArray(response.data) && response.data.length > 0) {
          settingWhiteLabel(response.data[0]);
          localStorage.setItem('company-code', response.data[0].companyCode);
          dispatch({ type: 'WHITELABEL_SETTING', playload: { data: response.data[0] } });
        }
      }
      callback();
    }
    fetchData(() => {
      dispatch(masterData());
      dispatch(hospitalLists());
      dispatch(masterIcd10());
    });
  }, []);

  useEffect(() => {
    const useLang = localStorage.getItem('i18nextLng') === null || localStorage.getItem('i18nextLng').indexOf('-') !== -1 ? 'th' : localStorage.getItem('i18nextLng');
    moment.locale(useLang);
  }, [i18n]);

  useEffect(() => {
    if (auth?.accessToken || cookie[`${SUB_DOMAIN}-accessToken`]) {
      setLoadAuth(true);
    } else {
      setLoadAuth(false);
    }
  }, [auth]);

  useEffect(() => {
    setCurrentRole(cookie?.currentRole);
  }, [cookie]);

  return (
    <>
      {(isLoading()) && (<Loading message={loading.message} />)}
      <ToastContainer hideProgressBar draggable={false} position={toastifyPosition} />

      <BrowserRouter>
        <InitialComponent />
        <Routes>
          <Route index path="/login" element={<SignIn />} />
          <Route index path="/daz/mini" element={<Mini />} />
          <Route index path="/daz/dashboard" element={<DashboardDaz />} />
          <Route index path="/version" element={<Version />} />
          <Route path="/" element={(<Suspense fallback={null}><AppLayout /></Suspense>)}>
            {loadAuth && currentRole && (
              routes.filter(route => !route?.roles?.length || (route?.roles?.length > 0 && route?.roles.includes(currentRole))).map(({ path, Element }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Element />
                  }
                />
              ))
            )}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
