import {
  IOMT_DATA,
  IOMT_CLEAR
} from '../actions/types';

const initialState = {
  data: [],
  detail: {},
  nextKey: ''
};

export default function IomtReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case IOMT_DATA:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore ? [...state.data, ...action.playload.data] : action?.playload.data
      };
    case IOMT_CLEAR:
      return {
        ...state,
        detail: {},
        status: undefined,
        message: undefined,
        isAddMore: false,
        nextKey: ''
      };
    default:
      return { ...state };
  }
}
