import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  PATIENT_FETCH,
  PATIENT_GET_HEALTH_RECORD,
  PATIENT_GET_DOCUMENT,
  PATIENT_CLEAR,
  PATIENT_UPDATE,
  PATIENT_HEALTH_FETCH
} from './types';

export const getPatients = (params = [], limit = 100, options = {
  attrType: 'full',
  columns: []
}, nextKey = '', isLoading = true) => async dispatch => {
  let isAddMore = false;
  const newParams = [
    `limit=${limit}`,
    ...params
  ];
  let url = `patients?${newParams.join('&')}`;

  if (options?.columns?.length > 0) {
    url += `&attrType=custom&${options.columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: PATIENT_CLEAR });
  dispatch({ type: IS_LOADING, status: isLoading, names: { PATIENT_FETCH: isLoading } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({
      type: PATIENT_FETCH,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_FETCH: false } });
  }).catch(err => {
    let error = err;
    dispatch({
      type: PATIENT_FETCH,
      playload: {
        data: [],
        status: 'success',
        isAddMore,
        nextKey: ''
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_FETCH: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getVitalSigns = (params = [], limit = 100, options = {
  attrType: 'full',
  columns: []
}, nextKey = '', isLoading = true) => async dispatch => {
  let isAddMore = false;
  const newParams = [
    `limit=${limit}`,
    ...params
  ];
  let url = `mypcu/vital_signs?${newParams.join('&')}`;
  if (options?.columns?.length > 0) {
    url += `&attrType=custom&${options.columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }
  dispatch({ type: PATIENT_CLEAR });
  dispatch({ type: IS_LOADING, status: isLoading, names: { PATIENT_HEALTH_FETCH: isLoading } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({
      type: PATIENT_FETCH,
      playload: {
        healthData: data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_HEALTH_FETCH: false } });
  }).catch(err => {
    let error = err;
    dispatch({
      type: PATIENT_HEALTH_FETCH,
      playload: {
        data: [],
        status: 'success',
        isAddMore,
        nextKey: ''
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_HEALTH_FETCH: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getPatientByCode = patCode => async dispatch => {
  const url = `patients?code=${patCode}`;

  dispatch({ type: PATIENT_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { PATIENT_UPDATE: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    const detail = data.find(o => o.patCode === patCode);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: PATIENT_UPDATE, playload: { detail, status } });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const patientCreate = dataSet => async dispatch => {
  const url = 'patients';

  dispatch({ type: PATIENT_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { PATIENT_UPDATE: true } });
  await axios.post(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    let dataDupLists = [];
    if (resData.error || resData.errors) {
      status = 'error';
      dataDupLists = resData?.dataDupLists || [];
    }
    dispatch({
      type: PATIENT_UPDATE,
      playload: {
        status,
        message,
        detail: resData?.data,
        dataDupLists
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: PATIENT_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const patientUpdate = (patCode, dataSet) => async dispatch => {
  const url = `patients/${patCode}`;

  dispatch({ type: PATIENT_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { PATIENT_UPDATE: true } });
  await axios.put(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: PATIENT_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: PATIENT_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const patientGetHealthRecord = (patCode, limit = 500, nextKey = '') => async dispatch => {
  let url = `patientHealthRecord?patCode=${patCode}&limit=${limit}`;
  let loading = true;

  let isAddMore = false;
  const columns = ['type', 'value', 'createdAt'];
  if (columns.length > 0) {
    url += `&attrType=custom&${columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }

  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
    loading = false;
  }

  dispatch({ type: PATIENT_CLEAR });
  dispatch({ type: IS_LOADING, status: loading, names: { PATIENT_GET_HEALTH_RECORD: loading } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    dispatch({
      type: PATIENT_GET_HEALTH_RECORD,
      playload: {
        dataHealthRecords: data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_GET_HEALTH_RECORD: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: PATIENT_GET_HEALTH_RECORD, playload: { dataHealthRecords: [], status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_GET_HEALTH_RECORD: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const patientGetDocument = patCode => async dispatch => {
  let url = `patientFiles?patCode=${patCode}&limit=100`;
  const columns = [];

  if (columns.length > 0) {
    url += `&attrType=custom&${columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }

  dispatch({ type: PATIENT_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { PATIENT_GET_DOCUMENT: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    dispatch({ type: PATIENT_GET_DOCUMENT, playload: { dataDocumentFiles: data, status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_GET_DOCUMENT: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: PATIENT_GET_DOCUMENT, playload: { dataDocumentFiles: [], status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { PATIENT_GET_DOCUMENT: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const referralPatientClear = () => dispatch => {
  dispatch({ type: PATIENT_CLEAR });
};

export const updateAccountPatient = async (patCode, data) => {
  const url = `/patients/${patCode}`;
  return new Promise(reslove => {
    axios.put(url, data).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
};

export const createdImportPatient = async data => {
  const url = '/patients';
  return new Promise(reslove => {
    axios.post(url, data).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
};

export const getQRcodByCompanyLink = async (code, patCode) => {
  const url = `/accounts/link?companyCode=${code}&userCode=${patCode}`;
  return new Promise(reslove => {
    axios.get(url).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
};

export const getPatientDataByCode = async patCode => {
  const url = `/patients?code=${patCode}`;
  return new Promise(reslove => {
    axios.get(url).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
};
