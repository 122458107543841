import { combineReducers } from 'redux';
import LoadingReducer from './LoadingReducer';
import AdminReducer from './AdminReducer';
import AlertReducer from './AlertReducer';
import AuthReducer from './AuthReducer';
import CompaniesReducer from './CompaniesReducer';
import SmsReducer from './SmsReducer';
import EmailReducer from './EmailReducer';
import FeedbackReducer from './FeedbackReducer';
import MasterReducer from './MasterReducer';
import HospitalReducer from './HospitalReducer';
import DoctorReducer from './DoctorReducer';
import DrugReducer from './DrugReducer';
import ReferralReducer from './ReferralReducer';
import RealtimeReducer from './RealtimeReducer';
import ChatsReducer from './ChatsReducer';
import PatientReducer from './PatientReducer';
import ReportReducer from './ReportReducer';
import WhitelabelReducer from './WhitelabelReducer';
import MedicineReducer from './MedicineReducer';
import CouponReducer from './CouponReducer';
import InsuranceReducer from './InsuranceReducer';
import PaymentReducer from './PaymentReducer';
import TokenReducer from './TokenReducer';
import DoctorWalletReducer from './DoctorWalletReducer';
import ArticleReducer from './ArticleReducer';
import IomtReducer from './IomtReducer';

export default combineReducers({
  loading: LoadingReducer,
  alert: AlertReducer,
  admin: AdminReducer,
  auth: AuthReducer,
  companies: CompaniesReducer,
  sms: SmsReducer,
  email: EmailReducer,
  feedback: FeedbackReducer,
  master: MasterReducer,
  hospital: HospitalReducer,
  doctor: DoctorReducer,
  drug: DrugReducer,
  referral: ReferralReducer,
  realtime: RealtimeReducer,
  chats: ChatsReducer,
  patient: PatientReducer,
  report: ReportReducer,
  whitelabel: WhitelabelReducer,
  medicine: MedicineReducer,
  coupon: CouponReducer,
  insurance: InsuranceReducer,
  payment: PaymentReducer,
  token: TokenReducer,
  doctorWallet: DoctorWalletReducer,
  article: ArticleReducer,
  iomt: IomtReducer
});
