import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  ARTICLE_DATA,
  ARTICLE_CLEAR,
  ARTICLE_UPDATE,
  ARTICLE_DETAIL
} from './types';

export const getArticles = (params = [], limit = 100, nextKey = '') => async dispatch => {
  let isAddMore = false;
  let url = 'article';
  const newParams = [
    `limit=${limit}`,
    ...params
  ];
  url += `?${newParams.join('&')}`;
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: ARTICLE_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { ARTICLE_DATA: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || response.data || [];
    dispatch({
      type: ARTICLE_DATA,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_DATA: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: ARTICLE_DATA, playload: { status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_DATA: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getArticle = articleCode => async dispatch => {
  const url = `article?code=${articleCode}`;

  dispatch({ type: ARTICLE_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { ARTICLE_DETAIL: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    const detail = data.find(o => o.articleCode === articleCode);
    dispatch({ type: ARTICLE_DETAIL, playload: { status: 'success', detail } });
    dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_DETAIL: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: ARTICLE_DETAIL, playload: { status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_DETAIL: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const articleCreate = dataSet => async dispatch => {
  const url = 'article';

  dispatch({ type: ARTICLE_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { ARTICLE_UPDATE: true } });
  await axios.post(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: ARTICLE_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: ARTICLE_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const articleUpdate = (code, dataSet) => async dispatch => {
  const url = `article/${code}`;

  dispatch({ type: ARTICLE_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { ARTICLE_UPDATE: true } });
  await axios.put(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: ARTICLE_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: ARTICLE_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};
