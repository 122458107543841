const WhiteLabelConfig = {
  mapping: [
    // Dev
    // {
    //   companyCode: 'CN1562652993102',
    //   appCode: 'co.doctoratoz.dev',
    //   domainName: 'devadmin.doctoratoz.co',
    //   companyName: 'DAZ'
    // },
    {
      companyCode: 'CN1598928649546',
      appCode: 'co.doctoratoz.mmt',
      domainName: 'devadminmmt.doctoratoz.co',
      companyName: 'มิตรไมตรีคลินิก (mmt)'
    },
    {
      companyCode: 'CN1610705650771',
      appCode: 'co.doctoratoz.chularat',
      domainName: 'devadminchg.doctoratoz.co',
      companyName: 'CHG Company'
    },
    // Prod
    // {
    //   companyCode: 'CN1568780494050',
    //   appCode: 'co.doctoratoz.dev',
    //   domainName: 'admin.doctoratoz.co',
    //   companyName: 'Doctor a to z'
    // },
    {
      companyCode: 'CN1688117055706',
      appCode: 'co.doctoratoz.puhealth',
      domainName: 'adminpuhealth.doctoratoz.co',
      companyName: 'กรมอนามัย'
    },
    {
      companyCode: 'CN1691739003644',
      appCode: 'co.doctoratoz.bkkgi',
      domainName: 'admin.healthtalkth.com',
      companyName: 'BkkGi'
    },
    {
      companyCode: 'CN1693232674837',
      appCode: 'co.doctoratoz.telepharmacy',
      domainName: 'admintelepharmacy.doctoratoz.co',
      companyName: 'Telepharmacy'
    },
    {
      companyCode: 'CN1610705529244',
      appCode: 'co.doctoratoz.chularat',
      domainName: 'adminchg.doctoratoz.co',
      companyName: 'CHG Company'
    },
    {
      companyCode: 'CN1658466861097',
      appCode: 'co.doctoratoz.wtcare',
      domainName: 'adminwt.doctoratoz.co',
      companyName: 'วังทอง'
    },
    {
      companyCode: 'CN1699870778015',
      appCode: 'co.doctoratoz.suphan',
      domainName: 'adminsuphan.doctoratoz.co',
      companyName: 'อบจ.สุพรรณบุรี'
    },
    {
      companyCode: 'CN1621321597697',
      appCode: 'co.doctoratoz.chiva',
      domainName: 'adminchiva.doctoratoz.co',
      companyName: 'CHIVA HISxHER'
    },
    {
      companyCode: 'CN1600835175233',
      appCode: 'co.doctoratoz.mmt',
      domainName: 'adminmmt.doctoratoz.co',
      companyName: 'มิตรไมตรีคลินิก (mmt)'
    },
    {
      companyCode: 'CN1660883798367',
      appCode: 'co.doctoratoz.chersery',
      domainName: 'adminch.doctoratoz.co',
      companyName: 'เฌ้อเซอรี่ โฮม'
    },
    {
      companyCode: 'CN1737608071806',
      appCode: 'co.doctoratoz.hs',
      domainName: 'adminhs.doctoratoz.co',
      companyName: 'Health Station'
    },
    {
      companyCode: 'CN1737607298055',
      appCode: 'co.doctoratoz.devhs',
      domainName: 'devadminhs.doctoratoz.co',
      companyName: 'Dev Health Station'
    }
  ]
};

export default WhiteLabelConfig;
