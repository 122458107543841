import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  COUPON_FETCH,
  COUPON_CLEAR,
  COUPON_CREATE,
  COUPON_UPDATE,
  COUPON_DATA
} from './types';

const urlMethod = 'coupons';

export const getCoupons = (params = [], limit = 100, options = {
  attrType: 'full',
  columns: []
}, nextKey = '') => async dispatch => {
  let isAddMore = false;
  const newParams = [
    `limit=${limit}`,
    ...params
  ];
  let url = `${urlMethod}?${newParams.join('&')}`;

  if (options?.columns?.length > 0) {
    url += `&attrType=custom&${options.columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: COUPON_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { COUPON_FETCH: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({
      type: COUPON_FETCH,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { COUPON_FETCH: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: COUPON_FETCH, playload: { data: [], status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { COUPON_FETCH: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const couponCreate = params => async dispatch => {
  const url = urlMethod;

  dispatch({ type: COUPON_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { COUPON_CREATE: true } });
  await axios.post(url, params).then(response => {
    const resData = response.data;
    const { message } = resData;
    const data = (resData?.data || resData);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: COUPON_CREATE, playload: { data, status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { COUPON_CREATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { COUPON_CREATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const couponUpdate = (code, dataSet) => async dispatch => {
  const url = `${urlMethod}/${code}`;

  dispatch({ type: COUPON_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { COUPON_UPDATE: true } });
  await axios.put(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: COUPON_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { COUPON_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: COUPON_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { COUPON_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getCouponByCode = (code, isLoading = true) => async dispatch => {
  const url = `${urlMethod}?code=${code}`;

  dispatch({ type: COUPON_CLEAR });
  dispatch({ type: IS_LOADING, status: isLoading, names: { COUPON_DATA: isLoading } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    const detail = data.find(o => o.code === code);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: COUPON_DATA, playload: { detail, status } });
    dispatch({ type: IS_LOADING, status: false, names: { COUPON_DATA: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { COUPON_DATA: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getRequestCouponByCode = async code => {
  const url = `${urlMethod}?code=${code}`;
  return new Promise(reslove => {
    axios.get(url).then(response => {
      reslove(response.data);
    }).catch(() => {
      reslove('');
    });
  });
};

export const couponCheck = async (code, patCode, appName, isDrug) => {
  const url = `${urlMethod}/checkcoupon/${code.toLocaleLowerCase()}`;
  let result = { code, msg: 'not_found_coupon' };
  return new Promise(reslove => {
    axios.put(url, { appName, checkBy: patCode, isDrug }).then(response => {
      const data = response?.data;
      if (data.success) {
        result = {
          code,
          type: data.data.discountType,
          amount: data.data.doctorCost,
          medicineType: data.data.medicineDiscountType,
          medicineAmount: data.data.medicineCost,
          useInsteadOfMoney: data.data.useInsteadOfMoney,
          isDemo: data.data.isDemo,
          volunteerOnly: data.data.volunteerOnly,
          freeDelivery: data.data.freeDelivery,
          allow: true
        };
        if (data.data.discountShareAllProcess) {
          result.type = data.data.discountShareProcessType;
          result.amount = data.data.discountShareProcessDoctor ? data.data.discountShareProcessAmounts : result.amount;
          result.share = {
            medicine: data.data.discountShareProcessMedicine,
            delivery: data.data.discountShareProcessDelivery
          };
        }
      } else {
        result = { code, msg: data.message };
      }
      reslove(result);
    }).catch(error => {
      if (error.response.data.message) {
        result = { code, msg: error.response.data.message };
      }
      reslove(result);
    });
  });
};
